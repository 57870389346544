console.log('Test');

// --------------------
// CSS
// --------------------

import 'scss/main.scss';


// --------------------
// Libraries
// --------------------

// Bootstrap
import 'popper.js'
import 'bootstrap'

// GSAP - Animations
import 'gsap'

// Select2
// import 'select2/dist/css/select2.min.css'
import 'select2'

// Datatables

import 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.min.css'


// --------------------
// Functions
// --------------------

// Tooltips 
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('.toast').toast()
})


// btn__top
$(document).ready(function(){
    $('.btn__top').click(function(){
        $('body, html').animate({
            scrollTop: '0px'
        }, 300);
    });
    $(window).scroll(function(){
        if($(this).scrollTop() > 0 ){
            $('.btn__top').slideDown(300);
        } else {
            $('.btn__top').slideUp(500);
        }
    });
});

// Feather Icons 
const feather = require('feather-icons')
feather.replace()


// Select2 

// Single
$('.select2').select2()
// Multiple  
$('.select2--multiple').select2()

// Datatables 

$('.datatable').DataTable({ 
    dom: '<<"datatable__header"fli><"datatable__body"t><"datatable__footer"p>>',
    language:	{
                    lengthMenu: "_MENU_", 
                    infoFiltered: "(filtered from _MAX_ total entries)",
                    info: "_START_ - _END_ of _TOTAL_",
                    search: "",
                    paginate:	{
                                    next: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>',
                                    previous: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>'
                                }
                }
});

// Box shadow on header when scrolling

$(window).scroll(function() {     
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
        $("header").addClass("active");
    }
    else {
        $("header").removeClass("active");
    }
});


// CAROUSEL MULTIPLE ITEMS
// Developed at agap2
// Based on:
// http://www.codeply.com/go/s3I9ivCBYH/multi-carousel-single-slide-bootstrap-4

$('.multi-item-carousel').on('slide.bs.carousel', function (e) {
    let $e = $(e.relatedTarget),
        itemsPerSlide = 3,
        totalItems = $('.carousel-item', this).length,
        $itemsContainer = $('.carousel-inner', this),
        it = itemsPerSlide - (totalItems - $e.index());
    if (it > 0) {
      for (var i = 0; i < it; i++) {
        $('.carousel-item', this).eq(e.direction == "left" ? i : 0).
          // append slides to the end/beginning
          appendTo($itemsContainer);
      }
    }
  });


// FILEUPLOAD
$(document).ready(function () {
    bsCustomFileInput.init()
  })